import Vue from 'vue';
import API from '@/plugins/Api';

function generateKey (path, params) {
  return path + (typeof params === 'object' && Object.keys(params).length > 0 ? JSON.stringify(params).replace(/[{}"]/g, '_') : '');
}

const state = {
  cache: {},
  timeouts: {},
};

const mutations = {
  populate (state, {key, data, timeout = undefined}) {
    Vue.set(state.cache, key, data);
    if (timeout) {
      Vue.set(state.timeouts, key, timeout);
    }
  },
  clear (state, key) {
    if (Object.prototype.hasOwnProperty.call(state.timeouts, key)) {
      clearTimeout(state.timeouts[key]);
    }
    Vue.delete(state.cache, key);
    Vue.delete(state.timeouts, key);
  },
};

const actions = {
  populateWithTimeout ({commit}, {path, params, data, timeout = 600}) {
    const key = generateKey(path, params);
    commit('clear', key);
    commit('populate', {
      key,
      data: data,
      timeout: setTimeout(() => {
        commit('clear', key);
      }, 1000 * timeout),
    });
  },
  async populateFromApi ({dispatch}, {path, params, timeout = 600}) {
    const result = await API.get(path, params);
    dispatch('populateWithTimeout', {
      path,
      params,
      data: result.data,
      timeout,
    });
  },
};
const getters = {
  get (state) {
    return (path, params, def) => {
      const key = generateKey(path, params);
      return Object.prototype.hasOwnProperty.call(state.cache, key)
        ? state.cache[key]
        : def;
    };
  },
  has (state) {
    return (path, params) => {
      return Object.prototype.hasOwnProperty.call(
        state.cache,
        generateKey(path, params),
      );
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
