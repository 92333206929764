export default {
  // задания
  assignments: 'Задания',
  assignment: 'Задание',
  assignmentId: 'Номер задания',
  newAssignment: 'Новое задание',
  forParcel: 'для посылки ',
  forConsolidation: 'сборки',
  verifyHtsCodes: 'Проверка кодов ТН ВЭД',
  assignee: 'Исполнитель',
  creator: 'Постановщик',
  type: 'Тип',
  assignmentType: 'Тип задания',
  attachFile: 'Прикрепить файл',
  selectFile: 'Выбрать файл',
  selectAnotherFile: 'Выбрать другой файл',
  selectFiles: 'Выбрать файл(ы)',
  selectAnotherFiles: 'Выбрать другой файл(ы)',
  comment: 'Комментарий',
  coomentOptional: 'Комментарий (опционально)',
  sendComment: 'Отправить',
  notAssigned: 'не назначен',
  startExecution: 'Начать выполнение',
  completeExecution: 'Завершить выполнение',
  addedStatus: 'добавил(а) статус',
  wrote: 'написал(а)',
  goToConsolidation: 'Перейти к Cборке',
  goToTask: 'Перейти к заданию',
  addComment: 'Добавить комментарий',
  assignmentCommentHelperText: 'Укажите дополнительный комментарий к заданию (опционально)',
  assignmentCancel: 'Отменить задание',
  areYouSureCancelAssignment: 'Вы уверены, что хотите хотите отменить задание',
  parcelsStatusWillBeChanged: 'Статус посылок будет изменен',
  // проверка ТН ВЭД
  listOfHtsCodesToCheck: 'Список кодов ТН ВЭД для проверки',
  goodsAreGroupedByHtsCodes: 'Все товары из Сборки сгруппированы по кодам ТН ВЭД',
  goodsAreAllowedByDefault: 'По умолчанию все коды (товары) считаются разрешенными (выделены зеленым)',
  howToMarkAsForbidden: 'Чтобы отметить, что код или товар являются запрещенными нажмите',
  howToMarkAsAllowed: 'Чтобы отметить, что код или товар являются разрешенными нажмите',
  howToAddComment: 'Чтобы добавить комментарий для кода или товара нажмите',
  markAsForbidden: 'Отметить как запрещенный',
  markAsAllowed: 'Отметить как разрешенный',
  quantityOfGoods: 'Количество товаров',
  pricePerGood: 'Стоимость одного товара',
  howToCompleteHtsCheck: 'После того как проверка всех кодов (товаров) завершена нажимите кнопку',
  verificationHtsCodeResult: 'Результаты проверки',
  // упаковка
  done: 'Готово',
  box: 'Картон',
  addBox: 'Добавить картон',
  addPallet: 'Добавить паллету',
  addToPallet: 'Добавить картон на паллету',
  boxes: 'Картоны',
  changeBoxSize: 'Изменить габариты картона',
  pallets: 'Паллеты',
  pallet: 'Паллета',
  packAssignmentAlgorithm: 'Алгоритм выполнения задания по упаковке',
  addBoxTask: 'Добавить картон (коробку) в которую будут добавляться посылки',
  addParcelTask: 'Нажать кнопку "Добавить посылку"',
  inputParcelCode: 'Просканировать или ввести вручную штрихкод посылки и нажать "Готово"',
  addPalletTask: 'Если необходимо сгрупировать картоны на паллету выделите нужные картоны и нажмите "Добавить картон на паллету"',
  completePackTask: 'После того как все посылки упакованы по коробкам нажмите "Завершить задание". Все посылки получат статус ',
  deleteBox: 'Удалить картон',
  deletePallet: 'Удалить паллету',
  packCompleteBtnMessage: 'Упакованы не все посылки',
  packCompleteBtnMessageBoxesRequireSize: 'Для завершения упаковки необходимо указать размеры всех картонов, не добавленных на паллету',
  boxQuantity: 'Кол-во картонов',
  customPalletSize: 'Ввести габариты',
  changePalletSize: 'Изменить габариты паллеты',
  boxCount: 'Кол-во картонов',
  // Поиск посылки
  searchParcel: 'Поиск посылки',
  searchParcelInfo: 'Найдите посылку на складе',
  searchParcelCodeInfo: 'Укажите штрихкод посылки которую нужно найти',
  notFound: 'не найдена',
  parcelWithCode: 'Посылка с штрихкодом',
  parcelFound: 'Посылка найдена',
  parcelNotFound: 'Посылка не найдена',
  found: 'найдена',
  awaitingConfirmation: 'Ожидает подтверждения',
  searchParcelPhotoInfo: 'Для завершения задания необходимо приложить фотографии посылки',
  assignmentAlreadyExist: 'Задание для одной или нескольких посылок уже назначено или выполнено',
  areYouSureNewAssignment: 'Вы уверены, что хотите добавить новое',
  findParcelAndScanCode: 'Найти посылку на складе и отсканировать ее штрихкод',
  takePhotoIfFind: 'Сделать фотографии посылки (если посылка найдена)',
  completeAssignment: 'Завершить выполнение',
  openParcel: 'Вскрыть посылку',
  parcelOpened: 'Посылка вскрыта',
  openParcelCodeInfo: 'Укажите штрихкод посылки которую нужно вскрыть',
  findParcelOnWarehouse: 'Найти посылку на складе',
  takePhotosOfTheOpenedParcel: 'Сделать фото содержимого посылки',
  openParcelAssignmentPhotoHelperText: 'Для завершения задания необходимо приложить фотографии содержимого посылки',
  // Изменить штрихкод/Переклеить наклейку
  parcelCodeMatch: 'Штрихкоды совпадают',
  parcelCodeDoesNotMatch: 'Штрихкоды не совпадают',
  changeLabel: 'Замена штрихкода (этикетки)',
  parcelNewLabel: 'Новая этикетка',
  oldParcelCode: 'Старый штрихкод',
  newParcelCode: 'Новый штрихкод',
  labelReplaced: 'Этикетка приклеена',
  labelReplacedHelperText: 'Поставьте отметку ниже, чтобы указать что новая этикетка была приклеена',
  label: 'Этикетка',
  parcelCodeUpdated: 'Штрихкод обновлен',
  parcelCodeDoesNotUpdated: 'Штрихкод не обновлен',
  scanNewParcelCode: 'Сканировать новый штрихкод',
  actionAlgorithm: 'Алгоритм выполнения',
  downloadAndPrintNewLabel: 'Скачать и распечатать новую этикетку',
  replaceOldLabel: 'Приклеить новую этикетку',
  checkboxCheck: 'Поставить отметку о том, что этикета была приклеена',
  inputNewParcelCodeOr: 'Отсканировать новую этикетку',
  scanNewParcelCodeFor: 'Нажать проверить, чтобы убедиться что отсканнированный штрихкод совпадает со штрихкодом указанным в задании',
  replaceFrom: 'Заменить с ',
  replaceOn: 'на',

  // доставки
  delivery: 'Доставка',
  consolidationDelivery: 'Доставка сборки',
  deliveryHelperText: 'Информация о получателе',
  deliveryAddress: 'Адрес доставки',
  deliveryService: 'Служба доставки',
  deliveryServices: 'Службы доставки',
  deliveryServiceName: 'Служба доставки',
  newDeliveryService: 'Новая служба доставки',
  editDeliveryService: 'Изменить службу доставки',
  // отправки
  receipt: 'Отправка',
  receipts: 'Отправки',
  receiptNumber: 'Номер отправки',
  receiptPhoto: 'Фото при принятии',
  newReceipt: 'Новая отправка',
  clientLabel: 'Маркировка',
  clientLabelHelperText: 'Загрузите фото накладной или этикетки курьерской службы, по которому можно определить какому клиенту принадлежит данная отправка',
  receiptPhotoHelperText: 'Загрузите фото накладной или этикетки курьерской службы, фото самой упаковки, а также фото на которых видна клиентская маркировка (если имеется)',
  noClientLabelHelperText: 'Поставьте отметку ниже если маркировка отсутствует и принадлежность отправки к конкретному клиенту была определена каким-либо иным образом',
  withLabel: 'Присутствует',
  withoutLabel: 'Отсутствует',
  noClientLabel: 'Маркировка клиента отсутствует',
  counterpartyHelperText: 'Выберите клиента из списка ниже',
  manifestCounterpartyHelperText: 'Выберите клиента для которого вы загружаете манифест',
  attention: 'Внимание',
  receiptAlreadyExist: 'Отправка с таким трекинг кодом уже существует',
  // посылки
  parcels: 'Посылки',
  parcelsDativePlural: '0 посылок | {n} посылку | {n} посылки | {n} посылок',
  allParcels: 'Все посылки',
  parcelCount: 'Кол-во посылок, шт',
  createParcel: 'Добавить посылку',
  addParcel: 'Добавить посылку',
  parcel: 'Посылка',
  addParcels: 'Добавить посылки',
  parcelsToAdd: 'Посылки для добавления',
  parcelBarcodesColumn: 'Штрихкоды посылок',
  errors: 'Ошибки',
  duplicates: 'Дубликаты',
  addParcelsByList: 'Добавить посылки из списка',
  addByHand: 'Добавить вручную',
  newParcel: 'Новая посылка',
  parcelCode: 'Штрихкод',
  parcelAlreadyExist: 'Посылка с таким штрихкодом уже существует',
  areYouSureCreate: 'Вы уверены, что хотите создать еще одну',
  parcelInOrder: 'Посылка добавлена в заказ',
  parcelParameters: 'Параметры посылки',
  // манифесты
  uploadManifest: 'Загрузить манифест',
  manifestProcessed: 'Манифест обработан',
  manifestProcessedTooltip: 'Манифест обработан успешно, проверьте результаты перед сохранением',
  manifestProcessingResult: 'Результат обработки манифеста',
  parcelsProcessedSuccessfully: 'Посылки обновлены',
  parcelsProcessedSuccessfullyHelperText: 'Информация о товарах в посылках добавлена (обновлена) в системе',
  parcelsFound: 'Посылки найдены в системе',
  parcelsFoundHelperText: 'После нажатия "Сохранить" информация о товарах для этих посылок будет записана в системе',
  parcelsProcessingErrors: 'Посылки с предупреждениями',
  parcelsProcessingErrorsHelperText: 'При обработке этих посылок обнаружены некоторые несоответствия',
  parcelsNotFound: 'Посылки не найдены в системе',
  parcelsNotFoundHelperText: 'Штрихкоды некоторых посылок из манифеста не были найдены в системе',
  parcelsHaveGoods: 'Информация о посылках уже есть в системе',
  parcelsHaveGoodsHelperText: 'Можно обновить информацию о посылках в системе или оставить их без изменений (по умолчанию)',
  parcelsWithSimilarCode: 'Посылки с одинаковыми штрихкодами',
  parcelsWithSimilarCodeHelperText: 'Некоторые штрихкоды посылок в системе повторяются',
  notInConsolidation: 'Не в сборке',
  // консолидации
  consolidationNumber: 'Номер сборки',
  consolidation: 'Сборка',
  consolidations: 'Сборки',
  newConsolidation: 'Новая сборка',
  createConsolidation: 'Создать сборку',
  setReturnConsolidation: 'Информация о сборке на возврат',
  consolidationName: 'Название сборки',
  consolidationdHelperText: 'Из посылок находящихся на складе можно формировать "Сборки". Формирование сборки является является обязательным условием для создания заказа в TROL',
  consolidationdNameHelperText: 'Для упрощения дальнейшей работы со сборкой требуется указать название (комментарий)',
  consolidationId: 'ID сборки',
  consolidationIdHelperText: 'Каждой сборке при создании будет присвоен уникальный идентификатор, который будет использован для связи с заказами в TROL',
  consolidationGoodsPrice: 'Стоимость товаров, €',
  parcelCodeExistsInConsolidation: 'Посылка с таким штрихкодом уже добавлена в сборку',
  consolidationStatus: {
    new: 'Новая',
    ordered: 'Сформирована',
    inProgress: 'Формируется',
  },
  consolidationAssignmentListHelperText: 'Список заданий связанных со сборкой',
  linkedConsolidations: 'Связанные сборки',
  linkedConsolidationsList: 'Список связанных сборок',
  linkConsolidations: 'Связать сборки',
  link: 'Связать',
  // группы пользователей
  userGroup: 'Группа пользователей',
  userGroups: 'Группы пользователей',
  newUserGroup: 'Новая группа пользователей',
  userGroupName: 'Название группы',
  userGroupHelperText: 'Введите название группы ниже - это название будет отображаться при создании создании заданий',
  userGroupEnabled: 'Группа включена (активна)',
  //
  goToTrolOrder: 'Перейти к заказу {id} в TROL',
  sendToVerify: 'Отправить на проверку',
  verify: 'Проверить',
  verificationTaskSent: 'Задание по проверке передано специалисту',
  startPacking: 'Начать упаковку',
  pack: 'Упаковка',
  packingTaskSent: 'Задание по сбору и упаковке передано специалисту',
  createOrder: 'Создать заказ',
  updateStatus: 'Обновить статус',
  targetStatus: 'Целевой статус',
  chooseConsolidation: 'Выберете сборку',
  chooseShipper: 'Выберете отправителя',
  addToConsolidation: 'Добавить в сборку',
  removeFromConsolidation: 'Удалить из сборки',
  moveToDifferentConsolidation: 'Перенести в другую сборку',
  consolidationsReturn: 'Возврат',
  consolidationsToReturn: 'Сборка на возврат',
  addConsolidationReturnInfo: 'Добавить информацию',
  consolidationReturnInfo: 'Информация о сборке на возврат',
  address: 'Адрес',
  index: 'Индекс',
  city: 'Город',
  country: 'Страна',
  consolidationReturnAddress: 'Адрес доставки',
  consolidationReturnAddressHelperText: 'Укажите адрес на какой будет совершен возврат сборки',
  consolidationReturnConsignee: 'Получатель',
  consolidationReturnContactPerson: 'Контактное лицо',
  consolidationReturnContactPhone: 'Контактный телефон',
  consolidationReturnComments: 'Комментарий к возврату',
  consolidationReturnCommentsHelperText: 'Добавте коментарий к сборке на возврат',
  consigneeHelperText: 'Укажите данные конечного получателя',
  contacts: 'Контакты',
  contactsHelperText: 'Укажите контакты для связи с получателем',
  true: 'Да',
  false: 'Нет',
  toolTipVerify: 'Для одной или нескольких посылок в сборке отсутствует инфо о товарах (коды ТН ВЭД)',
  toolTipForbidden: 'Для одной или нескольких посылок в сборке экспорт запрещен, либо посылка(и) не упакованы, либо привязаны сборки, по которым нельзя создать заказ',
  toolTipNotPacked: 'Одна или несколько посылок не прошли проверку (коды ТН ВЭД)',
  // заказы
  order: 'Заказ',
  orders: 'Заказы',
  // Контрагенты
  counterparty: 'Клиент',
  counterparties: 'Контрагенты',
  newCounterparty: 'Новый контрагент',
  mainCompany: 'Собственный контрагент',
  editCounterparty: 'Изменить контрагента',
  // Отправители
  shippers: 'Отправители',
  shipperPostCode: 'Почтовый код отправителя',
  shipperCountryCode: 'Код страны отправителя',
  // Дополнительные настройки
  AdditionalSettingsList: 'Дополнительные настройки',
  parcelWarehouseStorageTime: 'Срок хранения посылок на складе (в часах)',
  changeWarehouseStorageTimeEdit: 'Изменить срок хранения посылок на складе',
  changeSettings: 'Изменить настройки',
  // Доболнительные компоненты
  returnBackToList: 'Вернуться к списку',
  returnBackToReceipt: 'Вернуться к отправке',
  returnToConsolidation: 'Вернуться к сборке',
  // Статистика
  statistics: 'Статистика',
  ConsolidationStatisticsTitle: 'Сборки (и Заказы)',
  GoodsTitle: 'Товары',
  Total: 'Всего',
  totalVolumeM3: 'Общий объем, м³',
  averageVolumeM3: 'Средний объем, м³',
  totalWeightKg: 'Общий вес, кг',
  averageWeightKg: 'Средний вес, кг',
  withoutMarking: 'Без маркировки',
  averageInMonth: 'В среднем в месяц',
  inOneReceipt: 'В одной отправке',
  inOneConsolidation: 'В одной сборке',
  lengthWeightHeight: 'Длинна, см     Ширина, см     Высота, см',
  TotalProcessingTime: 'Общее время обработки (дней)',
  AverageProcessingTime: 'Среднее время обработки (чч:мм:сс)',
  itemCount: 'количество',
  minProductValue: 'Мин. цена',
  maxProductValue: 'Макс. цена',
  avgProductValue: 'Макс. цена',
  totalProductValueSum: 'Общая стоимость',
  averageProductValue: 'Средняя цена',
  maxParcelsInGoods: 'Макс. в посылке',
  avgConsolidationProductValue: 'Средняя стоимость товаров* в сборке',
  medianTimeBetweenAssemblyAndOrder: 'Медианное время между созданием сборки и заказа (чч:мм:сс)\n',
  // ---
  edit: 'Редактировать',
  dateExpired: 'Просрочена',
  parcelProcessingTimeExpiredTooltip: 'Превышено время хранения посылки на складе',
  update: 'Обновить',
  ignore: 'Игнорировать',
  action: 'Действие',
  editMenuButton: 'Редактировать',
  goTo: 'Перейти',
  showDisabled: 'Показать отключенные',
  hide: 'Скрыть',
  apply: 'Применить',
  createdTimestamp: 'Принято',
  currentStatusCreatedTimestamp: 'Обработано',
  enabledShort: 'Вкл',
  saved: 'Сохранено',
  save: 'Сохранить',
  objectName: 'Название',
  loginRequired: 'Требуется авторизация',
  setStatus: 'Установить статус',
  saveAndAddAnother: 'Сохранить и добавить еще',
  actions: 'Действия',
  addTask: 'Добавить задание',
  export: 'Экспорт',
  baseRate: 'Базовая ставка',
  reportGeneration: 'Генерация отчета',
  pieces: 'шт.',
  users: 'Пользователи',
  addUser: 'Добавить пользователя',
  awaitingProcessing: 'Ожидает обработки',
  quickSearch: 'Поиск',
  trackingCode: 'Трекинг код',
  created: 'Добавлено',
  received: 'Принято',
  create: 'Добавить',
  add: 'Добавить',
  added: 'Добавлено',
  delete: 'Удалить',
  accepted: 'Ожидает обработки',
  processing: 'Обрабатывается',
  processed: 'Обработано',
  markProcessed: 'Завершить обработку',
  filters: 'Фильтры',
  itemsPerPage: 'Отображать по',
  details: 'Подробнее',
  retryScan: 'Сканировать код',
  sizeAndWeight: 'Габариты и вес',
  sizeAndWeightHelperText: 'Все поля ниже обязательны к заполнению (можно вводить числа с разделителем)',
  weight: 'Вес, кг',
  weightKg: 'Вес, кг',
  kg: 'кг',
  width: 'Ширина, см',
  widthCm: 'Ширина, см',
  widthMm: 'Ширина, мм',
  height: 'Высота, см',
  heightCm: 'Высота, см',
  heightMm: 'Высота, мм',
  length: 'Длина, см',
  lengthCm: 'Длина, см',
  lengthMm: 'Длина, мм',
  dimensions: 'Габариты, см',
  status: 'Статус',
  currentStatusName: 'Статус',
  name: 'Имя',
  surname: 'Фамилия',
  orderDate: 'Дата заказа',
  parcelStatusTypes: 'Статусы посылок',
  newParcelStatusType: 'Новый статус посылки',
  default: 'По умолчанию',
  notifications: 'Уведомления',
  changesLocked: 'Изменения заблокированы',
  setAutomatically: 'Устанавливается автоматически',
  parcelStatusType: 'Статус посылки',
  upload: 'Загрузить',
  attachFiles: 'Прикрепить файл(ы)',
  attachConsolidations: 'Связать сборку(и)',
  unbindConsolidation: 'Отвязать сборку',
  consolidationReady: 'Создание заказа разрешено',
  consolidationNotReady: 'Создание заказа запрещено',
  files: 'Файлы',
  filesHelperText: 'Манифест, фото и другие документы',
  receiptFilesHelperText: 'Документы и прочее',
  documents: 'Документы',
  trolApiKey: 'API ключ TROL',
  present: 'Присутствует',
  absent: 'Отсутствует',
  change: 'Изменить',
  cancel: 'Отмена',
  undo: 'Отменить',
  yes: 'Да',
  no: 'Нет',
  size: 'Размер',
  download: 'Скачать',
  deleted: 'Удалено',
  canceled: 'Отменено',
  onOrderCreation: 'При создании заказа',
  onHtsVerification: 'При проверке ТН ВЭД',
  onHtsAllowed: 'Экспорт разрешен',
  onHtsForbidden: 'Экспорт запрещен',
  setAsPacking: 'Упаковывается',
  setAsAwaitingToPacking: 'Ожидает упаковки',
  setAsPacked: 'Упакована',
  canLogin: 'Активен',
  newUser: 'Новый пользователь',
  editUser: 'Изменить пользователя',
  password: 'Пароль',
  repeatPassword: 'Повторите пароль',
  email: 'Email',
  sorting: 'Сортировка',
  barcode: 'Штрихкод',
  barcodeHelperText: 'Можно отсканировать с помощью камеры или добавить вручную ниже',
  delivered: 'Доставлено',
  deliveredHelperText: 'Выберите службу доставки из предложенных ниже',
  volume: 'Объем, м³',
  price: 'Стоимость, €',
  login: 'Войти',
  rowsSkipped: 'строк пропущено',
  noData: 'Нет записей',
  noAssignments: 'Здесь пока нет заданий',
  noConsolidations: 'Здесь пока нет связанных сборок',
  noDocuments: 'Здесь пока нет документов',
  noFiles: 'Здесь пока нет файлов',
  noParcels: 'Здесь пока нет посылок',
  noGoods: 'Здесь пока нет товаров',
  noResults: 'Записи не найдены',
  uploadAnotherPhoto: 'Загрузить еще фото',
  uploadPhoto: 'Загрузить фото',
  uploadNewLabelPhoto: 'Загрузить фото новой этикетки',
  photo: 'Фото',
  loadingData: 'Загрузка данных',
  search: 'Поиск',
  createdFrom: 'Создано с',
  addedFrom: 'Добавлено от',
  addedTo: 'Добавлено до',
  createdTo: 'Создано по',
  processedFrom: 'Обработано с',
  parcelsLeft: 'Осталось посылок',
  processedTo: 'Обработано по',
  receivedFrom: 'Принято с',
  receivedTo: 'Принято по',
  goToStartingPage: 'Перейти на начальную страницу',
  accessDenied: 'Доступ запрещен',
  today: 'Сегодня',
  admin: 'Админ',
  clear: 'Сбросить',
  close: 'Закрыть',
  remove: 'Удалить',
  selected: 'Выбрано',
  select: 'Выбрать',
  check: 'Проверить',
  description: 'Описание',
  warning: 'Предупреждение',
  example: 'Пример',
  findInSystem: 'Найти в системе',
  addByList: 'Добавить списком',
  htsCode: 'Код ТН ВЭД',
  total: 'Итого',
  totalForbidden: 'Итого запрещенных',
  totalAllowed: 'Итого разрешенных',
  descriptionRu: 'Наименование товара на Русском языке',
  descriptionEn: 'Наименование товара на Английском языке',
  goodsDescription: 'Наименование товара',
  shipper: 'Отправитель',
  consignee: 'Получатель (ФИО)',
  productQuantity: 'Кол-во товаров, шт',
  productUnitValue: 'Стоимость товара',
  productUnitValueConverted: 'Стоимость товара в €',
  declaredValueCurrency: 'Валюта',
  declaredValueCurrencyConverted: 'Валюта €',
  netWeightKg: 'Вес нетто (кг)',
  grossWeightKg: 'Вес Брутто(кг)',
  goodsWeightKg: 'Вес нетто/брутто, кг',
  processingResult: 'Результат обработки',
  selectedParcelsAddedToConsolidation: 'Выбранные посылки добавлены в Сборку',
  ignoreAll: 'Игнорировать все',
  replaceAll: 'Заменить все',
  selectedFile: 'Выбранный файл',
  process: 'Обработать',
  processManifest: 'Обработать манифест',
  replace: 'Заменить',
  allowed: 'Разрешено',
  forbidden: 'Запрещено',
  notAllowed: 'Не разрешено',
  apiErrors: {
    'Validation Failed': 'Ошибка валидации',
    'Invalid credentials.': 'Неверные учетные данные.',
    'Consolidation already has an order.': 'Сборка уже имеет связанний заказ.',
    'Receipt has parcels.': 'Приемка имеет связанные посылки.',
    'This parcel is locked.': 'Изменение посылки запрещено.',
    'Parcel not found in consolidation.': 'Посылка не найдена в сборке.',
    'Parcel already added to consolidation.': 'Посылка уже добавлена в сборку.',
    'Parcel is locked.': 'Изменение посылки запрещено.',
    'Parcel not found.': 'Посылка не найдена.',
    'Cannot add parcels from different counterparties to one consolidation.': 'Нельзя добавить посылки разных контрагентов в одну сборку.',
    'Parcel and consolidation have different counterparties.': 'Посылка и сборка имеют разных контрагентов.',
    'Provided list has parcels from different counterparties.': 'Список содержит посылки разных контрагентов.',
    'Not all goods have been verified.': 'Не все товары проверены.',
    'Counterparty has no API key.': 'У контрагента отсутствует API ключ.',
    'Changes are blocked.': 'Изменения заблокированы.',
    'Multiple parcels with the same code.': 'Несколько посылок с одинаковым штрихкодом.',
    'No pack assignment found for consolidation.': 'Не найдено задание по упаковке для этой сборки',
    'There are parcels that are simultaneously in several consolidations.': 'Есть посылки, которые находятся одновременно в нескольких сборках.',
  },
  validation: {
    messages: {
      default: 'Ошибка валидации',
      'The base rate must not be empty if report generation is enabled.': 'Поле с базовой ставкой не должно быть пустым, если включено создание отчетов.',
      'This value should not be blank.': 'Это поле не может быть пустым.',
      'Passwords must match.': 'Пароли должны совпадать.',
      'At least one photo is required.': 'Необходимо добавить хотя бы одно фото.',
    },
  },
  accessRoles: {
    accessRoles: 'Права доступа',
    ROLE_CONS_ORDERS: 'Создание консолидаций и заказов (клиент)', // create consolidations/orders
    ROLE_PARCELS_RECEIPTS: 'Создание отправок и посылок (сотрудник склада)', // create parcels/receipts
    ROLE_ADMIN: 'Просмотр разделов админ (администратор)', // access to admin chapters
    ROLE_VIEW_STATISTICS: 'Просмотр раздела статистики',
  },
  notificationTypes: {
    'parcels.daily-processed': 'Посылки обработаные за день',
    'parcels.assignment-created': 'Создание заданий',
    'parcels.assignment-complete': 'Завершение заданий',
    'parcels.assignment-canceled': 'Отмена заданий',
    'parcels.daily-expired': 'Просроченные посылки',
  },
  assignmentStatuses: {
    new: 'Ожидает выполнения',
    assigned: 'Назначен исполнитель',
    'in-progress': 'В работе',
    done: 'Выполнено',
    canceled: 'Отменено',
  },
  commentAddedSuccessfully: 'Комментарий успешно добавлен',
};
